import styled from 'styled-components';
import {Link} from 'react-router-dom';
//Color Key:

export const colorMap = {
  light: {
    primary: '#34b3d1', // ohana blue
    secondary: '282c34', // dark shade of cyan-blue.
    background: '#f6f6f6', //greyest of greys
    text: '#333', //Dark charcoal 
    accent: '#ffd369', // ohana yellow
    secondaryBackground: '#f8f8f8' //pretty centrally grey
  },
  dark: {
    primary: '#34b3d1', 
    secondary: '#ffd369', 
    background: '#1e1e1e', 
    text: '#f6f6f6', 
    accent: '#2c2c2c', //dark shade of gray black
    secondaryBackground: '#333'
  }
};

const goldenRatio = 1.61803398875;


export const MainContent = styled.div`
  font-family: 'Helvetica', sans-serif;
  width: 100%;
  margin: 0 auto; 
  flex-grow: 1;
  box-sizing: border-box;
  overflow-wrap: break-word;

 
`;

export const Highlight = styled.span`
color: #34b3d1;
font-weight: bold;
`;


export const Bold = styled.span`
color: #282c34;
font-weight: bold;
`


export const Section = styled.section`
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1185px;
  margin: auto;
  @media (max-width: 430px) {
    padding-left: 10px;
  padding-right: 10px;
  }
 
`;

export const Title = styled.h2`
color: ${colorMap.light.primary};
font-size: calc((${goldenRatio} * 20px)*${goldenRatio});
margin:20px;
 
`;

export const Subtitle = styled.h2`
order: 1; 
font-size: calc(${goldenRatio} * 20px);
align-items: start;
text-align: center;
color: #FFD369;
-webkit-text-stroke-width: 1px; 
-webkit-text-stroke-color: #1f1f1f; 
margin:15px;

@media (max-width: 430px) {
  -webkit-text-stroke-width: 0px; 
  color: #1f1f1f;
}
`;

export const Text = styled.p`
  font-size: 20px;
  line-height: 1.6;
  color: ${colorMap.light.text};
  overflow-wrap: break-word;
  text-align: left;
  
`;
export const FooterNoteText = styled(Text)`
font-size: 14px;
margin: 0 auto;
max-width: 768px;

text-align: center;
@media screen and (min-width: 768px) { 
  width: 768px;
}
`;

export const NoteText = styled(Text)`
background-color: #f8f8f8;  
padding: 10px;            
border-left: 4px solid #34b3d1; 
margin: 20px 0;           
font-style: italic;   
`;

export const LinkText = styled.a`
  font-size: 20px;
  line-height: 1.6;
  color: ${colorMap.light.accent}
  margin-bottom: 15px;
  font-weight: bold;
  text-align: center;

  &:hover {
    text-decoration: none; 
  }
`;



export const HighlightTextBold = styled.p`
position: relative;

color: #282c34;
text-align: center;
margin: 5px;
font-size: 20px;
font-weight: bold;
@media screen and (max-width: 600px) { 
 font-size: 16px;
}
`;

export const LinkButton= styled(Link)`
width: fit-content;
position: relative;
background-color: #FFD369;
padding: 10px;
margin: 10px auto;
border-radius: 20px 20px 20px 20px;
text-wrap: nowrap;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
text-align: center;
display: block;
color: #333;

text-decoration: none;
transition: color 0.2s; 

&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 0; 
  width: 0%; 
  background-color: #34b3d1;
  transition: width 0.3s ease; 
  border-radius: 20px 20px 20px 20px;
  border-color: #1f1f1f;
}

&:hover {
  &::before {
    width: 100%;
  }

  & ${HighlightTextBold} {
    color: #FFD369;
    
  }
}
`;
