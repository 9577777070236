// HomePage.jsx
import React, { useContext} from "react";
import { Link } from 'react-router-dom';
import { Subtitle, MainContent } from '../../../App.styled'
import { AboutText, AboutTextContainer, AboutPictureContainer, HighlightBoxButton, HighlightTextBold, Pic, AboutContainer } from './Home.styled';

import homeData from "./HomeStories";

import { ThemeContext } from "../../../ColorContext";

function Home() {
    const {theme, toggleTheme} = useContext(ThemeContext)
    
    console.log(theme, toggleTheme);

    return (
        <MainContent>

            {homeData.map(story => {
                return (


                    <AboutContainer key={story.id}>
                        
                        <AboutPictureContainer>
                            <Pic src={story.picture} alt="pic" />

                            <HighlightBoxButton as={Link} to={story.learnMoreRoute}>
                                <HighlightTextBold>{story.moreInfo}</HighlightTextBold>
                            </HighlightBoxButton>

                        </AboutPictureContainer>

                        <Subtitle>{story.subtitle}</Subtitle>

                        <AboutTextContainer>
                            <AboutText>{story.description}</AboutText>
                            {story.description2 && <AboutText>{story.description2}</AboutText>}
                        </AboutTextContainer>
                    </AboutContainer>
                )
            })}

        </MainContent>
    );
}

export default Home; 
