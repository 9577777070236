import styled from 'styled-components';


//Color Key:
import { colorMap } from '../../../App.styled';
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0

export const HeaderContainer = styled.header`

  margin: auto;
  height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
  z-index: 2;primary
  border-bottom: 10px solid ${colorMap.light.primary};
  
  @media (min-height: 200px) {
    height: 300px;
  }
`;

export const Logo = styled.img`
width: 80vw;
height: auto;
margin: 2% auto;

@media (min-width: 200px) {
  width: 325px;
}



@media (min-width: 1250px) {
  width: 475px;
}


`;