import React from "react";
import { TitleSubText } from './FlagFootball.styled';
import { Title, Section,  Text, HighlightTextBold } from "../../../App.styled";
import { ButtonContainer, LinkBball } from "../Basketball/Basketball.styled";


import { Link } from 'react-router-dom';

function SummerTournament() {
    return (

        <Section>
            <Title>Flag Football Summer Tournament</Title>
            <TitleSubText>
                This one time event happening near you on September 21st. Use the links below to register now to reserve your team's spot. 
            </TitleSubText>
            <ButtonContainer>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLScY2_ulIP7H7C6rPbtWcvY7RE-oCURPgNZTDc50SfMPZTiS_w/viewform'}>
                    <HighlightTextBold>Team Registration</HighlightTextBold>
                </LinkBball>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSc-dZZmdelH5_g0whJPWeg54LME6-QrEu7Q9R5u4eYrdNYguw/viewform'}>
                    <HighlightTextBold>Player Registration</HighlightTextBold>
                </LinkBball>
            </ButtonContainer>

            <Text>
                Tournament will be held over the course of 1 day, and champion will be crowned later that day. This event is intended for
                adults at 18+, or with written consent from a legal guardian. Players must be registered before the day of the tournament, 
                or they will NOT be allowed to play. If you have any questions regarding the touranment, please use the links at the bottom of 
                any page to contact us, on instagram, or via email. Thanks for playing with Ohana sports. Mahalo! 
            </Text>


          
        </Section>

    );
}

export default SummerTournament;



