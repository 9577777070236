import React from 'react';
import Standings from './Standings';
import Schedule from './Schedule';
import { Subtitle } from '../../../App.styled';



const League = () => {
  return (
    <div>
      <Subtitle>Basketball Fall 24' Standings</Subtitle>
      <Standings />
      <Subtitle>Schedule</Subtitle>
      <Schedule />
    </div>
  );
};

export default League;