import React from "react";
import {  Highlight,  TitleSubText } from './FlagFootball.styled';
import { Title, Section, Subtitle, Text, HighlightTextBold  } from "../../../App.styled";
import { ButtonContainer, LinkBball } from "../Basketball/Basketball.styled";


import { Link } from 'react-router-dom';

function FlagFootball() {
    return (

            <Section>           
            <Title>Flag Football 2024</Title>
            <TitleSubText>
                Spring 24' Season begins June 9th, 2024
            </TitleSubText>

            

            <Text>
                Aloha, <Highlight>Flag Football Fans</Highlight>!
                Thank you for dropping by the Flag Football section of the Ohana Sports website.
                We're currently in the exciting process of updating this page to bring you a more dynamic
                and engaging experience.
            </Text>

            <Subtitle>Register below for the Spring Tournament</Subtitle>


            <ButtonContainer>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLScNXTr7WcbFezGIjiuoE_ple0Udulwi8Nrpk5kj5CZypyU11g/viewform'}>
                    <HighlightTextBold>Team Registration</HighlightTextBold>
                </LinkBball>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSdu_XB11LkvIjUgQVPehygxTVjFqq66khLKW_vyhaG558nmOw/viewform'}>
                    <HighlightTextBold>Player Registration</HighlightTextBold>
                </LinkBball>
            </ButtonContainer>


            <Text>
                Interactive Schedules & Scores: Stay up-to-date with the latest game schedules and scores, all in real-time.
                <Text>Player Spotlights: Get to know your favorite players with our in-depth profiles and stats.</Text>
                <Text> Enhanced Multimedia Content: Look forward to engaging photos, videos, and other multimedia content showcasing the thrilling moments of flag football.</Text>
                Community Features: We're building interactive elements to bring our flag football community closer together.
            </Text>

            <Subtitle>Additional Messaging</Subtitle>
            <Text>
                We understand the inconvenience caused during this update and sincerely appreciate your patience.
                Our goal is to create a vibrant and user-friendly platform for all flag football enthusiasts.
            </Text>

            <Text>
                Stay Tuned for Updates!
                We're just as excited as you are to launch the new Flag Football page. Keep an eye on our updates,
                and we'll let you know as soon as it's ready to go live.
            </Text>

            <Text>
                If you have any questions or need assistance in the meantime, please don't hesitate to reach out to us.
                We're here to help!
            </Text>

            <Text>
                Mahalo for Supporting Ohana Sports!
            </Text>
        </Section>

    );
}

export default FlagFootball;