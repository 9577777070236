import React, { useEffect } from "react";
import { LinkButton, HighlightTextBold } from '../../../App.styled';
import { Link } from 'react-router-dom';
import { Title, Section, Text,   Highlight } from "../../../App.styled";


function YouthCamps() {


    const year = new Date().getFullYear();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (

        <Section>
            
            <Title>Youth Development Camp {year}!</Title>


            <LinkButton as={Link} to={'https://docs.google.com/forms/d/1aLtRn80NDHJpaAnAeWKOcQ7fRv18Paj-AWK9A8jNbo4/viewform?edit_requested=true'}>
                <HighlightTextBold>Click Here to Register</HighlightTextBold>
            </LinkButton>


            <Text>
                Join us for an unforgettable experience designed for ages 6-12, promising skill enhancement and loads of fun.camp will be hosted this summer, dates are TBD.  Our experienced coaches, with backgrounds in high school and college sports, are committed to providing a nurturing environment where every participant becomes an integral part of our extended sports family.
            </Text>
            <Text>
                At <Highlight>Ohana Sports</Highlight>, you're not just a player or a spectator; you're part of something truly special. This spring, we invite you to share your passion for sports and be a part of our community where learning, growth, and play go hand in hand.
            </Text>
            <Text>
                Our 2024 Youth Camp offers introductory sessions where kids will dive into the fundamentals of various sports, including basketball, non-contact flag football, golf, kickball and volleyball. Throughout the camp, participants will not only develop their skills but also foster essential team-building abilities such as collaboration, communication, and sportsmanship.
            </Text>

            <Text>
                We ensure a rewarding and enjoyable experience for young athletes. Come celebrate the unifying power of sports with us and become a part of a community that cares. Let's make this spring break one to remember!
            </Text>
           
            <Text>
                For any inquiries or assistance, feel free to reach out to us. We're here to ensure you have the best experience with Ohana Sports.
            </Text>

            <Text>
                Mahalo for Supporting Ohana Sports!
            </Text>
        </Section>

    );
}

export default YouthCamps;