import styled from 'styled-components';
import { AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai';
import { colorMap } from '../../../App.styled';

export const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  background-color: ${colorMap.light.primary}; 
  color: ${colorMap.light.text};
  height: auto;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
`;
export const AddressLink = styled.a`
  color: ${colorMap.light.background}; 
  text-decoration: none; 

  &:hover {
    text-decoration: underline; 
    color: ${colorMap.light.accent}; 
  }
`;
export const ContactText = styled.p`
  font-size: 16px;
  color: ${colorMap.light.background}; 
  
`;
export const FooterText = styled.div`
  font-size: 18px; 
  justify-content: center;
  padding-bottom: 5px;
 
`;

export const FooterSocialIcons = styled.a`
  margin: 5px 10px; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: ${colorMap.light.text}; 
  background-color: white; 
  transition: transform 0.3s ease, color 0.3s linear;

  &:hover {
    transform: scale(1.1);
    color: ${colorMap.light.accent}; 
  }
`;

export const StyledInstagram = styled(AiOutlineInstagram)`
height: 75%;
width: 75%;
transition: transform 0.3s ease, color 0.3s linear;
&:hover {
    transform: scale(1.1);
    color: red; 
  }
`;

export const StyledEmail = styled(AiOutlineMail)`
height: 75%;
width: 75%;
transition: transform 0.3s ease, color 0.3s linear;
&:hover {
    transform: scale(1.1);
    color: ${colorMap.light.accent}; 
  }
`;

export const SocialIconsContainer = styled.div`
    display: flex;
    margin-top: 10px;
    justify-content: center;

`;

