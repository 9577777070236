import { MenuBar, MenuContainer, MenuItem, DropdownContent } from './Menu.styled';
import React, { useState } from 'react';

const Menu = () => {
    const [isOpen] = useState(false);
    return (
        <>
    <MenuBar>
      <MenuContainer open={isOpen}>
        <MenuItem to="/">Home
        </MenuItem>
        <MenuItem to="/About">About
        </MenuItem>
        <MenuItem>  Sports
          <DropdownContent className="dropdown-content">
            <MenuItem to="/Basketball">Basketball</MenuItem>
            <MenuItem to="/FlagFootball">Flag Football</MenuItem>
            <MenuItem to="/SummerTournament">Summer Tournament</MenuItem>
            <MenuItem to="/Kickball">Kickball</MenuItem>
          </DropdownContent>
        </MenuItem>
        <MenuItem to="/youthCamps">Spring Camp</MenuItem>
      </MenuContainer>
    </MenuBar>                    
        </>
    );
};

export default Menu;


