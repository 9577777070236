import styled from 'styled-components';
//Color Key:
import { colorMap } from '../../../App.styled';
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0

export const StyledBurger = styled.button`
  position: absolute;
  top: 5%;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1000;

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${colorMap.light.text};
    background: ${({ isOpen }) => isOpen ? colorMap.light.accent : colorMap.light.text};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    // Add transformations for open state if needed
  }

  @media (min-width: 769px) {
    display: none; // Hide burger menu on larger screens
  }
`;
