import styled from "styled-components";
import { colorMap } from "../../../App.styled";

export const Container = styled.section`
    
    font-family: 'Arial', sans-serif;
    max-width: 768px;
    border: 1px solid ${colorMap.light.primary};
    margin: 20px auto;
    background: ${colorMap.light.background};
    border-radius: 4px;
    overflow: hidden; 
    padding: 10px;
    @media screen and (min-width: 768px) { 
        width: 768px;
      }
  
`