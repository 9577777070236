import YouthPic from "../../logo/YouthFootball.jpeg"
import RecLeages from "../../logo/BasketballActionShot.jpeg"
// import RunningFlag from "../../logo/FootballAction3.jpeg"
import fballPic from "../../logo/FootballHandShake.jpeg"


const homeData = [
    {
        id: 1,
        subtitle: 'Flag Football Summer Tournament',
        description: `Join us on September 21st for a Flag Football Tournament you won't forget!`,
        description2: `Register early to ensure your spot in this action packed tournament. (18+)`,
        picture: fballPic,
        moreInfo: `Registration Open Now!`, 
        learnMoreRoute: `/SummerTournament`                 
    }, 
    {
        id: 2,
        subtitle: 'Recreational Leagues', 
        description: `We have a wide offering of sports in the Kirkland/Eastside area! Join us for Basketball, 
        Flag Football, Kickball and more! These leagues are intend to be competitive and fun for all, with all 
        skill levels welcome.`,
        description2: `Spring basketball begins August 31st. Register your team now!`,
        picture: RecLeages, 
        moreInfo: `Register for Spring Basketball`, 
        learnMoreRoute: `/Basketball`               
    }, 
    {
        id: 3,
        subtitle: 'Youth Development Camps', 
        description: `Discover dynamic youth development camps in Basketball, Flag Football, and Golf,
        set to launch this spring break and summer. Designed for ages 6-12, these
        programs promise skill enhancement and fun. Stay tuned for more details!`,
        description2: `To register or learn more about our spring camp, click the button for more info.`,
        picture: YouthPic,
        moreInfo: `Click Here To Learn More!`,
        learnMoreRoute: `/YouthCamps`              
    }
]

export default homeData;

