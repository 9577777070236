// Menu.styled.jsx
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorMap } from '../../../App.styled';

//Color Key:

// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6  
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0

export const MenuBar = styled.div`

  background-color:${colorMap.light.text};
  font-family: 'Futura', sans-serif; 
  width: 100%;
  z-index: 999;
  position: sticky; 
  top: 0;
  @media (max-width: 768px) {
    display: none; 
}
`;


export const MenuContainer = styled.nav`
  max-width: 960px;
  margin: 0 auto; 
  padding-top: 5px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between; 
`;


//#1f1f1f
export const MenuItem = styled(Link)`
  color: ${colorMap.light.accent}; 
  letter-spacing: 4px;
  text-decoration: none; 
  cursor: pointer;
  padding: 10px 15px; 
  display: inline-flex; 
  flex-wrap: nowrap;
  font-size: 16px;
  position: relative; 
  transition: .2s; 

  &:hover {
    background-color: ${colorMap.light.accent}; 
    color: ${colorMap.light.primary};
    border-radius: 10px;
    .dropdown-content {
      display: flex; 
      flex-direction: column; 
      flex-wrap: nowrap; 
      left: 15%;  
      top: 100%; 
      z-index: -1;
    }
  }
;
`;

//gold #ffd369 blue #34b3d1
export const DropdownContent = styled.div`
  display: none; 
  letter-spacing: 2px;
  position: absolute; 
  color: ${colorMap.light.accent}; 
  min-width: 8rem;
  text-align: left;
  background-color: ${colorMap.light.text}; 
  font-size: 1rem;
  z-index: 1;

  a {
    color: ${colorMap.light.accent}; 
    padding: .4em .5em; 
    text-decoration: none;
    text-align: center;
    &:hover { 
      background-color: ${colorMap.light.accent}; 
      color: ${colorMap.light.primary}; 
    }
  }
  @media (max-width: 768px) {
    width: 100%; 
    top: 100%;
  }
`;




