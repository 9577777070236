import React from "react";
import { Section, Text, Subtitle } from "../../../App.styled";



function About() {
    return (
      
            <Section>
           
           

                <Subtitle>Our Story</Subtitle>

                <Text>
                    
                    At Ohana Sports, our journey began with a simple vision: to create a sports community 
                    where everyone is welcome, and every game is an opportunity to connect. Founded in the 
                    heart of Kirkland, we've grown from a small group of sports enthusiasts into a thriving 
                    family of athletes, volunteers, and supporters, all united by a love for the game and a 
                    commitment to inclusivity.
                </Text>

                <Subtitle>Our Mission</Subtitle>

                <Text>
                    
                    Our mission is to provide engaging, enjoyable, and inclusive sporting experiences that do 
                    more than just bring people together—they create a sense of belonging and foster genuine 
                    community spirit. We're dedicated to promoting health, wellness, and personal development 
                    through every pass, play, and point scored.
                </Text>
                <Subtitle>Serving the Eastside!</Subtitle>
                <Text>
                 Our impact extends beyond the field and into the heart of the Kirkland community. Through 
                 partnerships, events, and volunteer initiatives, we strive to make recreational fun accessible to
                 all, regardless of age or skill level. Whether you're a seasoned athlete or a newcomer to the world 
                 of sports, there's a place for you in the Ohana family.
                </Text>

                <Subtitle>Join the Family!</Subtitle>

                <Text>
                As we continue to grow and evolve, our doors are always open to new members, volunteers, and partners 
                who share our passion for sports and community. Explore our programs, get involved, and let's make a 
                difference together. Welcome to Ohana Sports—where every game is a chance to belong, connect, and thrive.
                </Text>

           

                <Text>
                    If you have any questions or need assistance in the meantime, please don't hesitate to reach out to us.
                    We're here to help!
                </Text>

                <Text>
                    Mahalo for Supporting Ohana Sports!
                </Text>
            </Section>
       
    );
}

export default About;