import React from "react";
import { StyledBurger } from './Burger.styled';
import Menu from './Menu/Menu'; // Import the Menu component from the Menu directory


const Burger = ({ isOpen, setIsOpen }) => {
    return (
         <>
            <StyledBurger isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
                <div />
                <div />
                <div />
            </StyledBurger>
            <Menu isOpen={isOpen} />
        </>
    );
};




export default Burger;
