// HomePage.jsx
import React from "react";
import { Logo, HeaderContainer } from './Header.styled';
import logo from '../../logo/logo.svg';

function Header () {
    return (
        <HeaderContainer>
            <Logo src={logo} alt="Logo" />
        </HeaderContainer>
    );
}

export default Header;
