import React from 'react';
///import React, { useState, useEffect } from 'react';
import { StandingsTable, StandingsHeader, StandingsRow, TeamCell, StatCell } from './Styles/Standings.styled';
import { FooterNoteText } from "../../../App.styled";
import jsonData from '../Basketball/fall24.json';
import { Link, useNavigate } from 'react-router-dom';

const Standings = () => {


    let navigate = useNavigate();

    function handleClick(team) {
        console.log(jsonData[0].schedule)
        console.log(team)
        navigate(`/team/${team}`);
    }

    // const [schedule, setScheduleData] = useState([]);

    // useEffect(() => {
    //     setScheduleData(jsonData[0].Schedule);
    // }, []);

    let standings = {
       
    };
    




jsonData[0].Schedule.forEach(week => {
    week.games.forEach(game => {
        let { team1, team2, score1, score2 } = game;

        if (score1 !== null && score2 !== null && !(score1 === 0 && score2 === 0)) {
            if (!standings[team1]) standings[team1] = { wins: 0, losses: 0, pointsFor: 0, pointsAgainst: 0, matchups: {} };
            if (!standings[team2]) standings[team2] = { wins: 0, losses: 0, pointsFor: 0, pointsAgainst: 0, matchups: {} };

            if (score1 > score2) {
                standings[team1].wins++;
                standings[team2].losses++;
                standings[team1].matchups[team2] = (standings[team1].matchups[team2] || 0) + 1;
            } else {
                standings[team1].losses++;
                standings[team2].wins++;
                standings[team2].matchups[team1] = (standings[team2].matchups[team1] || 0) + 1;
            }

            standings[team1].pointsFor += score1;
            standings[team1].pointsAgainst += score2;
            standings[team2].pointsFor += score2;
            standings[team2].pointsAgainst += score1;
        }
    });
});


console.log('standings', standings )




// let teamStandings = Object.keys(standings).map(team => ({ team, ...standings[team] }));
// teamStandings.sort((a, b) => {
//     if (b.wins !== a.wins) {
//         return b.wins - a.wins;
//     } else {
//         // Using || 0 to handle undefined matchups
//         const result = (b.matchups[a.team] || 0) - (a.matchups[b.team] || 0);
//         if (result !== 0) {
//             console.log('first', b.matchups[a.team], 'second', a.matchups[b.team]); 'first' is THROWING UNDEFINED 
//             return result;
//         }
//     }
    
//     if (b.pointsFor !== a.pointsFor) {
//         return b.pointsFor - a.pointsFor;
//     } else if (b.pointsAgainst !== a.pointsAgainst) {
//         return a.pointsAgainst - b.pointsAgainst;
//     }

//     throw new Error(`Unable to resolve tie between ${a.team} and ${b.team}`);
// });

let teamStandings = Object.keys(standings).map(team => ({ team, ...standings[team] }));


function sortWins(arr) {
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr.length - 1; j++) { 
            if (arr[j].wins < arr[j + 1].wins) {

                console.log(arr[j])
                let temp = arr[j];
                arr[j] = arr[j + 1];
                arr[j + 1] = temp;
            }
        }
    }
}


console.log('stands', teamStandings);
console.log(sortWins(teamStandings));



teamStandings.forEach((team, index) => {
    team.place = index + 1;
});

    

    return (
        <div>
        <StandingsTable>

            <thead>
                <StandingsRow>
                    <StandingsHeader>Place</StandingsHeader>
                    <StandingsHeader>Team</StandingsHeader>
                    <StandingsHeader>W</StandingsHeader>
                    <StandingsHeader>L</StandingsHeader>                   
                </StandingsRow>
            </thead>
            <tbody>
                {teamStandings.map((team) => (
                    <StandingsRow key={team.team}>
                        <StatCell>{team.place}</StatCell>
                        <TeamCell onClick={() => handleClick(team.team)}><Link to={`/team/${team.id}`}>{team.team}</Link></TeamCell>
                        <StatCell>{team.wins}</StatCell>
                        <StatCell>{team.losses}</StatCell>
                        
                    </StandingsRow>
                ))}
            </tbody>
          
        </StandingsTable>
        <FooterNoteText>*Tiebreakers: Matchups, then points scored, then points against.</FooterNoteText>
        </div>
        
    );
};

export default Standings;












