import React from 'react';

import {
    FooterContainer, FooterText, FooterSocialIcons, StyledInstagram, StyledEmail, SocialIconsContainer, ContactText
} from './Footer.styled'; 

function Footer() {
    const year = new Date().getFullYear(); 
   


    return (
        <FooterContainer>
            <FooterText>

                <ContactText>
                    To register or any questions, please email us or send a message on Instagram.
                </ContactText>
                <SocialIconsContainer>
                <FooterSocialIcons
                    href="https://www.instagram.com/_ohanasports_/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                        <StyledInstagram />
                 
                </FooterSocialIcons>
                <FooterSocialIcons
                    href="mailto:ohanasportsllc@gmail.com"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    
                        <StyledEmail />
                </FooterSocialIcons>
            </SocialIconsContainer>
                &copy; Copyright OhanaSports {year} 
            </FooterText>
            
        </FooterContainer>

    );
}

export default Footer;
