import styled from 'styled-components';
import { colorMap } from '../../../App.styled';

export const Section = styled.section`
  width: 75%;
  margin: auto;

  
`;

export const AddressLink = styled.a`
  color: ${colorMap.light.background}; 
  text-decoration: none; // or "underline" if you want it underlined
  text-align: center;

  &:hover {
    text-decoration: underline; // if you want underline on hover
    color: ${colorMap.light.accent}; // color when hovered
  }
`;
export const Title = styled.h1`
  color: ${colorMap.light.secondary};
  font-size: 1.8em;
  margin-bottom: 10px;
`;

export const Subtitle = styled.h2`
  color: ${colorMap.light.secondary};
  font-size: 1.5rem;
  margin-bottom: 8px;
`;

export const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  color: ${colorMap.light.secondary};
  margin-bottom: 15px;
  text-align: left;
  
`;
export const HighlightedText = styled(Text)`
  background-color: ${colorMap.light.accent};
  color: ${colorMap.light.text};
  padding: 20px;
  margin: 30px 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-style: italic;
`;

export const List = styled.ul`
  list-style: inside circle;
  width: 70%;
  color: ${colorMap.light.secondary};
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding-left: 20px;
`;

export const ListItem = styled.li`
font-size: 1rem;
  margin-bottom: 10px;
  margin-top:1rem;
`;

export const Highlight = styled.span`
  color: ${colorMap.light.secondary};
  
  font-weight: bold;
`;

export const TitleSubText = styled.h2`
  color: ${colorMap.light.secondary};
  font-size: 1.3rem;
 
`;

export const ScheduleLink = styled.a`
  text-decoration: underline;
  font-weight: bold;
  
  &:hover {
    text-decoration: none; 
`;

export const RegistrationMessage = styled.div`

`