import './App.css';
import React, {useState, useEffect} from "react";
import Home from "./assets/components/Home/index";
import Menu from "./assets/components/Menu/index";
import FlagFootball from "./assets/components/FlagFootball/index";
import Basketball from "./assets/components/Basketball/index";
import Footer from "./assets/components/Footer/index";
import League from "./assets/components/League";
import {MainContent} from "./App.styled";
import Burger from "./assets/components/BurgerDropDown/Burger"
import YouthCamps from "./assets/components/YouthCamps/index";
import About from './assets/components/About';
import Header  from './assets/components/Header';
import Kickball from './assets/components/Kickball';
import Team from "./assets/components/Team"
import SummerTournament from './assets/components/FlagFootball/SummerTournament';
//import PlayoffBracket from './assets/components/League/PlayoffBracket';
import ThemeProvider from './ColorContext';

import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
     
} from "react-router-dom";


function App() {

    const [isOpen, setIsOpen] = useState(() => {
        return false; 
    })
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
    }, [isOpen]);
    return (
        <>
            <Router>
            <ThemeProvider>

                <div className='App'>

                  
                    <Burger isOpen={isOpen} setIsOpen={setIsOpen} />
                    <Menu isOpen={isOpen} />
                   <Header/>
                    <MainContent>
                        <Routes>
                        <Route path="/" element={<Home />} />
                            <Route path="/FlagFootball" element={<FlagFootball />} />
                            <Route path="/SummerTournament" element={<SummerTournament />} />
                            <Route path="/Basketball" element={<Basketball />} />
                            <Route path="/Kickball" element={<Kickball />} />
                            <Route path="/Basketball/Schedule" element={<League />} />           
                            {/* <Route path="/Basketball/PlayoffBracket" element={<PlayoffBracket />} /> */}
                            <Route path="/About" element={<About />} />
                            <Route path="/YouthCamps" element={<YouthCamps />}/>   
                            <Route path="/team/:teamId" element={<Team/>}/>
                
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        
                    </MainContent>
                    <Footer />
                </div>
                </ThemeProvider>

            </Router>
        </>

    );
}


export default App;
