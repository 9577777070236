import React from "react";
import { useEffect, useState } from "react";
import { Container } from "./Team.styled"
import { useParams, useNavigate  } from "react-router-dom";
import jsonData from '../Basketball/fall24.json';

import Week from "./Week"

function Team() {

    const [schedule, setScheduleData] = useState([]);
    let { teamId } = useParams();
    let navigate = useNavigate();
    useEffect(() => {
        setScheduleData(jsonData[0]?.Schedule || []);
    }, []);

    useEffect(() => {
        if (teamId === undefined || teamId === 'undefined') {
            navigate('/Basketball/Schedule', { replace: true });
        }
    }, [teamId, navigate]);

console.log( teamId)



    console.log(schedule.map(week => {
        return week
    }))

    return (

        <Container>
            
            <h1>Spring Schedule for {teamId}</h1>
            {schedule.map((week, weekIndex) => (
                <Week week={week} weekIndex={weekIndex} teamId={teamId}/>
            ))}
        </Container>
    );
}

export default Team;



