import React from "react";
import { Highlight } from './Kickball.styled';
import { Title, Section, Subtitle, Text,  HighlightTextBold } from "../../../App.styled";
import { ButtonContainer, LinkBball } from "../Basketball/Basketball.styled";
import { Link } from "react-router-dom";

function Kickball() {
   


    return (
      
        <Section>
       
            <Title>Kickball Coming Soon!</Title>

            <ButtonContainer>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSeEU9DVa6hk8I8YIrNhzapmPNLDQa_EU1WXBoiKWAF9s0sTdQ/viewform'}>
                    <HighlightTextBold>Team Registration</HighlightTextBold>
                </LinkBball>
                <LinkBball as={Link} to={'https://docs.google.com/forms/d/e/1FAIpQLSel3Cc7HZqXCrhSh1atU2bwaRCcyl-DmZLw7utPe1EyS6FpZg/viewform'}>
                    <HighlightTextBold>Player Registration</HighlightTextBold>
                </LinkBball>
            </ButtonContainer>

            <Text>
                Who wants to play some <Highlight>kickball</Highlight>?
                Co-ed Kickball is coming to Ohana Sports! 
            </Text>

            <Subtitle>Stay Tuned!</Subtitle>

            <Text>
                Check in for regular updates. 
                We're constantly updating our site with the latest information and features to enhance your experience.
                Don't strike out on these updates!
            </Text>

            <Text>
                We're committed to providing a comprehensive and engaging experience for all Kickball fans.
                Keep this page bookmarked for the latest schedules, scores, and updates.
            </Text>

            <Text>
                For any inquiries or assistance, feel free to reach out to us. We're here to ensure you have the best experience with Ohana Sports.
            </Text>

            <Text>
                Mahalo for Supporting Ohana Sports!
            </Text>
        </Section>
       
    );
}

export default Kickball;