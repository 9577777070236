// HomePage.styled.jsx
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colorMap } from '../../../App.styled';


//Color Key:
// Logo blue: #34b3d1
// Logo Mustard Yellow: #ffd369
// background grey: #f6f6f6
// dark blue/black #333
// dark blue #282c34
// score grey: #edeef0
// logo black: #



export const AboutContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  border-bottom: 2px solid #34b3d1;
  box-sizing: border-box;
  margin: 1rem 0;
  width: 100%;

  &:last-child {
    border-bottom: none;
  }
  
  @media screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto 1fr;
    padding: 2rem;
    
    &:nth-child(even) {
      & > :first-child {
        grid-column: 2 / 3; /* Moves the first child to the second column for even containers */
      }
      & > :last-child {
        grid-column: 1 / 2; /* Moves the last child to the first column for even containers */
      }
    }
  }
`;


export const AboutTextContainer = styled.div`
  // Mobile-first styles
  max-width: 100%;
  padding: 1rem 2rem;
  height: auto;
  order: 3; 

  @media screen and (min-width: 768px) {
    min-width: 80%
  }
  @media screen and (min-width: 1070px) {
    padding: 0 2rem; 
  }

`;



export const AboutText = styled.p`
  // Mobile-first styles
  color: ${colorMap.light.text};
  line-height: 1.2;
  font-size: 20px; 
  text-align: left;
  margin: auto;
  width: 95%; // Use full width on mobile
  padding: 1rem 0; 

  @media screen and (min-width: 768px) {
    line-height: 1;
    font-size: 20px; // Start with the smallest size
    overflow-wrap: break-word;
  }

  @media screen and (min-width: 1070px) {
    font-size: 20px; 
    line-height: 1.4;
    width: 100%; 
  }
`;

export const AboutPictureContainer = styled.div`
  height: auto; 
  position: relative;
  box-sizing: border-box;
  padding-bottom: 2.5rem;
  order: 2; // Picture second
  width: 100%; 
  margin-bottom: 1rem; 
  @media screen and (min-width: 768px) { 
    width: 100%;
    order: 1;
    display: block; 
    margin-bottom: 1rem;
    grid-row: 1 / span 2;
  }

`;

export const Pic = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 50%; 
  object-fit: cover; 


  @media screen and (min-width: 768px) {
    height: 400px;
    width: 400px;
    border-radius: 10px; 
    height: auto; 

  }
  @media screen and (min-width: 1070px) {
    height: 550px;
    width: 550px;
    border-radius: 10px;
    height: auto;
  }
`;

export const HighlightTextBold = styled.p`
  position: relative;
  z-index: 1;
  color: ${colorMap.light.secondary};
  text-align: center;
  margin: 5px;
  font-size: 20px;
  font-weight: bold;
`;




export const HighlightBoxButton = styled(Link)`
  width: fit-content;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${colorMap.light.accent};
  padding: 10px;
  margin: 0 auto;
  border-radius: 0px 20px 0px 20px;
  text-wrap: nowrap;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: block;
  color: ${colorMap.light.text};
  
  text-decoration: none;
  transition: color 0.2s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0; 
    width: 0%;
    background-color: ${colorMap.light.text};
    transition: width 0.3s ease; 
    border-radius: 0px 20px 0px 20px;
  }

  &:hover {
    &::before {
      width: 100%;
    }

    & ${HighlightTextBold} {
      color: ${colorMap.light.accent};
    }
  }
`;




export const Highlight = styled.span`
color: ${colorMap.light.primary};
font-weight: bold;
`;


export const Bold = styled.span`
color: ${colorMap.light.text};
font-weight: bold;
`
