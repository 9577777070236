import styled from 'styled-components';
import {LinkButton} from "../../../App.styled"
import { colorMap } from '../../../App.styled';

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center; 
`;

export const List = styled.ul`
  list-style: inside circle;
  width: 70%;
  color: ${colorMap.light.secondary};
  flex-direction: column;
  margin: auto;
  text-align: left;
  padding-left: 20px;
`;

export const ListItem = styled.li`
font-size: 1rem;
  margin-bottom: 10px;
  margin-top:1rem;
`;

export const Highlight = styled.span`
  color: ${colorMap.light.secondary};
  font-weight: bold;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LinkBball = styled(LinkButton)`
@media screen and (max-width: 600px) { 
  padding: 5px;
  max-width: 200px;
}
`